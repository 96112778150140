<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="md-12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="md-12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Edit Workflow Category
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card>
      <validation-observer
        ref="workFlowCategoryUpdateForm"
        #default="{invalid}"
      >
        <b-form @submit.prevent="submitForm">
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Name*"
                label-for="h-team-name"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="h-team-name"
                    v-model="name"
                    placeholder="Name"
                    :state="(errors.length > 0 || nameValidation) ? false : null"
                    name="name"
                    @input="nameValidation == true ? nameValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameValidation"
                    class="text-danger"
                  >
                    {{ nameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-team-status"
                  label-cols-md="3"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-team-status"
                    v-model="status"
                    label="title"
                    :options="option"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Color"
                vid="color"
                rules="required"
              >
                <b-form-group
                  label="Color Scheme*"
                  label-for="h-activity-type-color"
                  label-cols-md="3"
                  :state="(errors.length > 0 || colorValidation) ? false : null"
                >
                  <b-form-input
                    id="h-activity-type-color"
                    v-model="color"
                    type="color"
                    :state="(errors.length > 0 || colorValidation) ? false : null"
                    name="color"
                    @input="colorValidation == true ? colorValidation = false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="colorValidation"
                    class="text-danger"
                  >
                    {{ colorError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="md-6" />

            <b-col cols="md-9">
              <validation-provider
                #default="{ errors }"
                name="Workflows"
                vid="workFlows"
                rules="required"
              >
                <b-form-group
                  label="Workflows*"
                  label-for="h-team-workFlows"
                  label-cols-md="2"
                  :state="(errors.length > 0 || workFlowValidation) ? false : null"
                >
                  <b-form-tags
                    v-model="workFlows"
                    size="lg"
                    add-on-change
                    no-outer-focus
                  >
                    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-1"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            :title="tag"
                            :disabled="disabled"
                            variant="danger"
                            @remove="removeTag(tag)"
                          >
                            {{ resolveWorkFlowsName(tag) }}
                          </b-form-tag>
                        </li>
                      </ul>
                      <b-form-select
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                      >
                        <template v-slot:first>
                          <!-- This is required to prevent bugs with Safari -->
                          <option
                            disabled
                            value=""
                          >
                            Choose a Workflow....
                          </option>
                        </template>
                        <option
                          v-for="(workFlow, ind) in workFlowOptions"
                          :key="ind"
                          :value="workFlow._id"
                        >
                          {{ workFlow.name }}
                        </option>
                      </b-form-select>
                    </template>
                  </b-form-tags>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="workFlowValidation"
                    class="text-danger"
                  >
                    {{ workFlowError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                :to="{ name: 'operation-workflow-category-index' }"
              >
                Cancel
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                :disabled="invalid"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">Save Changes</span>
              </b-button>
            </li>
          </b-nav>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BNav, BFormTags, BFormTag, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required, min } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BNav,
    BFormTags,
    BFormTag,
    BFormSelect,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      status: 'active',
      workFlows: [],
      color: '#2ABA86',
      colorError: 'Valid color is required',
      colorValidation: false,
      nameError: 'Valid name is required',
      nameValidation: false,
      workFlowError: 'Valid workflow is required',
      workFlowValidation: false,
      statusError: 'Valid status is required',
      statusValidation: false,
      option: [{ title: 'Active', code: 'active' }, { title: 'Inactive', code: 'inactive' }],
      workFlowOptions: [],

      // validation rules
      required,
      min,
    }
  },
  beforeMount() {
    this.$http.get(`operation/work-flow-categories/respond-with/workflow-options/${this.$route.params.id}`)
      .then(response => {
        this.workFlowOptions = response.data.workFlowOptions ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    this.$http.get(`operation/work-flow-categories/${this.$route.params.id}`)
      .then(response => {
        this.status = response.data.status ?? ''
        this.name = response.data.name ?? ''
        this.color = response.data.color ?? '#2ABA86'
        this.workFlows = response.data.workFlows ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    resolveWorkFlowsName(id) {
      const workFlow = this.workFlowOptions.find(o => o._id === id)
      if (workFlow) {
        return workFlow.name
      }
      return ''
    },
    submitForm() {
      this.$refs.workFlowCategoryUpdateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('name', this.name)
          formData.append('status', this.status)
          formData.append('color', this.color)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.workFlows.length; i++) {
            formData.append('workFlows[]', this.workFlows[i])
          }
          this.$http.patch(`operation/work-flow-categories/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message ?? '',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'operation-workflow-category-index' })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'workFlows') {
                    this.workFlowError = validationError.msg
                    this.workFlowValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'color') {
                    this.colorError = validationError.msg
                    this.colorValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
